import {
  PortableTextComponentProps,
  PortableTextTypeComponent
} from '@portabletext/react'
import { ImageProps as NextImageProps } from 'next/image'
import { useEffect, useState } from 'react'

import { Heading4, Paragraph } from 'src/components/atoms/Typography'
import { CtaBlockResult } from 'src/components/layouts/Page/SanityContentPage/types'
import {
  CmsCtaButton,
  CmsCtaButtonProps
} from 'src/components/molecules/CmsCtaButton'

import { InternalLink } from 'src/config/generated/sanitySchema'
import { getSanityImageUrl } from 'src/utils/sanity'

import { BodyElement } from '../types'

const DEFAULT_IMG_SRC = '/img/default.png'

const ActionBlockMask = ({ url }: { url: string }) => {
  return (
    <svg
      viewBox='0 0 800 240'
      fill='none'
      height={240}
      width={800}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      className='absolute -z-10 hidden lg:block right-0'
    >
      <image
        style={{
          clipPath: 'url("#mask")'
        }}
        xlinkHref={url}
        x={400}
      />
      <defs>
        <clipPath id='mask'>
          <path d='M 476.58429,7.3679688e-4 A 275.1683,159.24207 0 0 0 441.65656,77.346437 a 275.1683,159.24207 0 0 0 36.00781,77.958983 141.05264,130.23727 0 0 0 -69.10156,84.69532 h 391.4375 V 7.3679688e-4 Z' />
        </clipPath>
      </defs>
    </svg>
  )
}

type ActionBlockProps = Omit<CtaBlockResult, '_type' | 'link'> & {
  link: CmsCtaButtonProps
  wide?: boolean
}

export const ActionBlock = ({
  title,
  backgroundImage,
  intro,
  link,
  wide = false
}: ActionBlockProps) => {
  const [imgSrc, setImgSrc] = useState<NextImageProps['src']>()
  useEffect(() => {
    try {
      if (!backgroundImage) setImgSrc(DEFAULT_IMG_SRC)
      else {
        const url = getSanityImageUrl(backgroundImage, 800, 240)
        setImgSrc(url)
      }
    } catch (error) {
      setImgSrc(DEFAULT_IMG_SRC)
    }
  }, [backgroundImage])

  return (
    <div className='z-0 my-5 md:my-10 relative bg-mostlyGreen text-white md:rounded-[5px] rounded-md overflow-hidden h-auto md:h-[240px]'>
      <ActionBlockMask url={imgSrc as string} />
      <div
        className={`flex flex-col m-5 md:m-10 bg-transparent items-start justify-center max-w-[410px] ${
          wide ? 'xl:max-w-[700px]' : ''
        }`}
      >
        <div className='gap-2 flex flex-col items-start'>
          <Heading4 className='text-white !m-0'>{title}</Heading4>
          <Paragraph className='w-full font-normal'>{intro}</Paragraph>
        </div>
        <CmsCtaButton size='large' {...link} />
      </div>
    </div>
  )
}

export const CtaBlock: PortableTextTypeComponent<BodyElement> = ({ value }) => {
  if (!value) return null
  const { backgroundImage, intro, title, link } = value as CtaBlockResult

  return (
    <ActionBlock
      backgroundImage={backgroundImage}
      intro={intro}
      title={title}
      link={link}
    />
  )
}

type OldCtaBlockResult = Omit<CtaBlockResult, 'link'> & {
  link: {
    text: string
    url: string
  }
}

export const CtaBlockOld = ({
  value
}: PortableTextComponentProps<ActionBlockProps>) => {
  if (!value) return null
  const { backgroundImage, intro, title, link } =
    value as unknown as OldCtaBlockResult

  return (
    <ActionBlock
      backgroundImage={backgroundImage}
      intro={intro}
      title={title}
      link={{
        text: link.text,
        linkType: 'internal',
        internalLink: {
          _type: 'internalLink',
          path: link.url
        } as InternalLink
      }}
    />
  )
}
